.poll .container {
  width: 530px;
  max-width: 100%;
}

.pollAnswers {
  margin: 0;
  padding: 30px 0;
  list-style-type: none;
  counter-reset: feed;
}

.pollAnswer {
  padding-bottom: 30px;
}

.pollAnswerInner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pollAnswerInner::before {
  counter-increment: feed;
  content: counter(feed);
  display: block;
  width: 42px;
}

.pollAnswerHandle {
  width: 50px;
  display: grid;
  place-content: center;
  height: 100%;
  cursor: grab;
  font-size: 2.6rem;
  font-weight: bold;
  opacity: 0;
  transition: transform 200ms ease;
  color: currentColor;
}

.pollAnswer:hover .pollAnswerHandle {
  opacity: 1;
}

.pollAnswerHandle:hover {
  transform: scale(1.1);
}

.pollAnswerContent {
  flex: 1;
  border-radius: 12px;
  padding-right: 16px;
  height: 120px;
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  background-color: white;
}

.pollAnswer:hover .pollAnswerContent {
  background-color: #ebebeb;
}

.pollAnswerFields {
  display: grid;
  column-gap: 16px;
  padding: 10px 10px 10px 0;
  width: 100%;
  grid-template-columns: 1fr 1fr 180px;
  align-items: center;
  height: 100%;
}

.poll .answerImage,
.poll .answerImage :global(.ant-upload.ant-upload-drag) {
  height: 98px;
}

.pollAnswerErrors {
  padding-left: 42px;
}

.pollAnswer.images .pollAnswerFields {
  grid-template-columns: 1fr 180px;
}

.pollAnswer.images {
  width: 800px;
  max-width: 100%;
}
