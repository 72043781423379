.search {
  width: auto;
}

.search :global(.ant-input-group) {
  font-size: 1.8rem;
  line-height: 3;
  color: initial;
  display: flex;
  flex-direction: row-reverse;
}

.search :global(.ant-input-affix-wrapper) {
  width: 140px;
  background: none;
  padding: 4px 0 4px 32px;
  box-shadow: none;
  transition: all 150ms linear;
  border-radius: 0;
  font-size: inherit;
  line-height: inherit;
  border-color: transparent;
  transform: translate(0, 0);
}

.search :global(.ant-input-affix-wrapper-focused) {
  width: 400px;
}

.search :global(.ant-input-affix-wrapper-focused),
.search :global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
  border-bottom-color: var(--color-primary);
}

.search :global(.ant-input-group .ant-input-affix-wrapper:not(:last-child)) {
  border-radius: 0;
  border-width: 0 0 2px 0;
}

.search :global(.ant-input-group-addon) {
  width: auto;
  background: none;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.search :global(.ant-input-search-button) {
  border: none;
  background: none;
  box-shadow: none;
}

.search :global(.ant-input-search-button:after) {
  display: none;
}

.search :global(.ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary)) {
  color: rgba(0, 0, 0, 0.15);
}

.search :global(.ant-input-affix-wrapper > input.ant-input) {
  font-size: inherit;
  line-height: inherit;
  border: none;
}

.search :global(.ant-input-clear-icon) {
  padding-top: 2px;
}

.search :global(.ant-btn .anticon) {
  font-size: 1.25em;
}
