.color .colorButton {
  width: 32px;
  height: 32px;
  box-shadow: 0 0 4px 1px var(--color-black12);
  transition: all 200ms ease;
  border: 1px solid white;
}

.color .colorButton,
.color .colorButton:hover {
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><path d="M8 0h8v8H8zM0 8h8v8H0z"/></svg>');
}

.color .colorButton:hover {
  box-shadow: 0 0 8px 1px var(--color-black12);
}
