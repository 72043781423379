.lineup {
  display: grid;
  height: 100vh;
}

.content {
  overflow: auto;
}

.title,
.title:hover {
  display: flex;
  color: currentColor;
  opacity: 1;
}

.title svg {
  margin-right: 16px;
  flex: 0 0 28px;
}

.title svg path {
  fill: currentColor;
}

.title:hover {
  text-decoration: underline;
}

.preview {
  position: relative;
  display: grid;
  width: 411px;
  margin: 0 auto;
  transform: scale(0.7299270072992701); /* make .preview width=300px, with iframe width=375px that scaled down */
  transform-origin: 20% 0;
}

.preview img,
.preview iframe {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
}

.preview img {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  pointer-events: none;
}

.preview iframe {
  position: absolute;
  z-index: 0;
  border: none;
  height: calc(100% - 32px);
  width: calc(100% - 36px);
  border-radius: 28px;
}

.feed {
  --feed-item-handle-width: 50px;
  --feed-item-counter-width: 50px;

  margin: 0;
  padding: 0;
  list-style-type: none;
  counter-reset: feed;
}

.feedItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
}

.feedItem::before {
  counter-increment: feed;
  content: counter(feed);
  display: block;
  width: var(--feed-item-counter-width);
}

.feedItemHandle {
  width: var(--feed-item-handle-width);
  display: grid;
  place-content: center;
  height: 100%;
  cursor: grab;
  font-size: 2.6rem;
  font-weight: bold;
  opacity: 0;
  transition: transform 200ms ease;
  color: currentColor;
}

.feedItem:hover .feedItemHandle {
  opacity: 1;
}

.feedItemHandle:hover {
  transform: scale(1.1);
}

.feedItemContent {
  width: calc(100% - var(--feed-item-handle-width));
  height: 100%;
}

.feedItemContent > :first-child,
.feedItemContent > :first-child > :last-child {
  overflow: hidden;
}

.feedItemLink {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
}

.feedItemLink,
.feedItemLink:hover {
  color: currentColor;
}

.feedItemLink:hover {
  opacity: 1;
  text-decoration: underline;
}

.feedItemI {
  flex: 1;
  border-radius: 12px;
  padding-right: 16px;
  height: 70px;
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  background-color: white;
  width: calc(100% - var(--feed-item-counter-width));
}

.voted .feedItemI {
  border-color: transparent;
  background-color: #f2f2f2;
}

.feedItem:not(.checkIn):not(.checkInStop):hover:not(.voting) .feedItemI {
  border-color: black;
}

.active .feedItemI {
  background-color: var(--color-primary);
  border-color: transparent;
  color: white;
}

.dragging .feedItemI {
  box-shadow: 0 0 10px 2px var(--color-black38);
}

.feedItem:not(.active):not(:hover) .feedItemActions {
  display: none;
}

.btnDelete {
  color: var(--color-danger)
}
