.upload label {
  display: block;
  cursor: pointer;
}

.label {
  margin-bottom: 0.8rem;
}

.placeholder svg {
  color: var(--color-primary);
}

.upload :global(.ant-upload) {
  border-color: #cccccc;
  border-radius: 6px;
}

.upload :global(.ant-upload.ant-upload-btn) {
  padding: 40px 16px;
  min-height: 200px;
}

:global(.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover) {
  border-color: var(--color-primary);
}

img.asset {
  width: 100%;
  box-shadow: 0 0 5px 1px var(--color-gray20);
  border-radius: 6px;
  max-height: 300px;
  object-fit: contain;
}

.asset:not(img) {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.preset1 :global(.ant-upload.ant-upload-btn) {
  min-height: 0;
  height: 100%;
  padding: 8px;
}

.preset1 .asset,
.preset1 .remove {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preset1 .asset {
  height: 100%;
  box-shadow: none;
}

.preset1 .preview .remove,
.preset1 .preview .remove:hover {
  width: 100%;
  height: 100%;
  border-radius: 0;
  display: none;
}

.preset1:hover .preview .remove {
  display: flex;
  background-color: rgba(255, 255, 255, 0.95);
}

.preset1 :global(.ant-upload.ant-upload-drag) {
  background-color: #fff;
  overflow: hidden;
}

.preset1 .loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
