.field label {
  display: block;
  margin-bottom: 0.8rem;
}

.field textarea {
  font-size: initial;
  line-height: initial;
  border-radius: 6px;
  padding: 12px;
  border: none;
  outline: none;
  box-shadow: inset 0 0 0 1px var(--color-gray20);
  background-color: white;
  transition: box-shadow 200ms ease;
  text-align: inherit;
}

.field :global(.ant-input:focus),
.field :global(.ant-input-focused) {
  box-shadow: inset 0 0 0 2px var(--color-primary);
}

.field :global(.ant-input-textarea) {
  position: relative;
}

.field :global(.ant-input-textarea-show-count::after) {
  position: absolute;
  bottom: calc(100% + 4px);
  right: 0;
  font-size: 1.1rem;
  color: var(--color-black60);
  word-spacing: -0.2rem;
  letter-spacing: -0.05rem;
}
