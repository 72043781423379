.toggle label {
  display: block;
  cursor: pointer;
}

.label {
  margin-bottom: 0.8rem;
}

.inlineValue {
  color: black;
}

.checked .inlineValue {
  color: var(--color-primary);
}

:global(.ant-switch) {
  background: var(--color-secondary);
}

:global(.ant-switch-checked) {
  background: var(--color-primary);
}
