.stats {
  --nav-height: 64px;

  position: relative;
  padding-top: calc(var(--nav-height) + 32px);
}

.container {
  width: 1064px;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 32px;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: var(--nav-height);
  background-color: #fff;
  border-bottom: 1px solid var(--color-black12);
}

.navLink {
  padding: 8px 32px;
  display: block;
}

.navLink:hover {
  color: var(--color-primary-a);
}

.navLink.active {
  color: var(--color-primary);
}

.navLink[aria-disabled='true'] {
  opacity: 0.45;
  cursor: not-allowed;
  color: inherit;
}

.header {
  margin-bottom: 24px;
}

.table:not(.votesTable) :global(.ant-table) {
  font-size: 1.4rem;
}

.table :global(.ant-table-column-title) {
  white-space: nowrap;
}

.table :global(.ant-table-wrapper) {
  overflow: auto;
}

.table :global(.ant-table-thead) {
  background-color: hsl(0deg 0% 97%);
}

.table :global(.ant-table-tbody) {
  background-color: hsl(0deg 0% 93%);
}

.votesTable :global(.ant-table-thead) > tr > th,
.votesTable :global(.ant-table-tbody) > tr > td,
.votesTable :global(.ant-table) tbody td:first-child:hover {
  color: black;
}

.votesContent {
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
}

.sections {
  padding-bottom: 40px;
}

.section {
  width: 400px;
  margin: 0 auto 40px;
  max-width: 100%;
  padding: 0 20px;
}

.section h3 {
  margin: 0 0 16px;
}

.section :global(.ant-statistic) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
}

.section :global(.ant-statistic-title) {
  margin: 0 16px 0 0;
}

.actions {
  justify-content: center;
  padding-bottom: 24px;
}

.stats :global(.ant-statistic-content) {
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .stats {
    padding-top: calc(var(--nav-height) + 16px);
  }

  .container {
    padding: 0;
  }

  .navLink {
    padding-left: 16px;
    padding-right: 16px;
  }
}
