.pageHeader {
  display: grid;
  height: 164px;
  padding: 0 44px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  align-items: center;
}

.pageHeader h1 {
  margin: 0;
}

.pageContent {
  padding: 54px 44px;
}

.pageContent h2 {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 16px;
  margin-bottom: 32px;
}
