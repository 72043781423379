/*
  program editor
*/

.headerFixed .header {
  transform: translateY(122px) scaleY(0.5);
  background-color: white;
  transition: transform 200ms ease;
}

.headerFixed .header > :global(.ant-row) {
  transform: scaleY(2);
}

.headerFixed .header h1 {
  display: none;
}

.section {
  margin-bottom: 5rem;
}

.section h2 {
  border-bottom: 1px solid var(--color-black12);
  margin: 0 0 3rem;
  padding: 1.7rem 0;
  font-weight: 400;
  color: var(--color-black60);
}

.section:global(.active) h2 {
  color: var(--color-primary);
}

.fields {
  width: 530px;
}

.field {
  margin-bottom: 24px;
}

.regFieldsRow {
  padding: 19px 0 18px;
  border-bottom: 1px solid var(--color-black12);
}

.regFieldsRow :global(.ant-col):not(:first-child) {
  display: flex;
  justify-content: center;
}

.regLabelField {
  width: 100%;
  margin-top: 16px;
}
