.select {
  --field-height: 48px;
}

.select label {
  display: block;
  cursor: pointer;
}

.label {
  margin-bottom: 0.8rem;
}

:global(.ant-select) {
  display: block;
  font-size: 1.6rem;
  line-height: 1;
  color: inherit;
}

:global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  height: var(--field-height);
  padding: 0 40px 0 12px;
  border: none;
  outline: none;
  box-shadow: inset 0 0 0 1px var(--color-gray20);
  border-radius: 6px;
  background-color: white;
  transition: box-shadow 200ms ease;
}

:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector) {
  box-shadow: inset 0 0 0 2px var(--color-primary);
}

:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-search) {
  right: 40px;
  left: 12px;
  line-height: var(--field-height);
}

:global(.ant-select-single .ant-select-selector .ant-select-selection-item) {
  line-height: var(--field-height);
}

.dropdown {
  --item-height: 48px;

  border-radius: 6px;
  padding: 0;
  color: inherit;
  font-size: 1.6rem;
  line-height: 1.5;
}

.dropdown :global(.ant-select-item) {
  height: var(--item-height);
  font-size: inherit;
  line-height: var(--item-height);
  padding: 0 12px;
}

.dropdown :global(.ant-select-item-option:not(.ant-select-item-option-disabled):hover) {
  color: var(--color-primary);
  background-color: white;
}

.dropdown :global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)),
.dropdown :global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled):hover) {
  font-weight: normal;
  color: white;
  background-color: var(--color-primary-a);
}

.select :global(.ant-select-arrow .anticon:not(.ant-select-suffix)) {
  pointer-events: none;
}
