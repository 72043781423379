.actions {
  display: none;
  flex-flow: row nowrap;
  align-items: center;
}

tr:hover .actions {
  display: flex;
}

.actions > button:not(:first-child),
.actions > a:not(:first-child) {
  margin-left: 1.4rem;
}

.actions > a {
  display: block;
}

.actions button {
  padding-bottom: 0.35rem;
}
