@import '~antd/dist/antd.css';
/*noinspection CssInvalidAtRule*/
@import-normalize;

:root {
  --color-primary: hsl(255deg 79% 54%);
  --color-primary-a: hsl(255deg 79% 64%);
  --color-secondary: hsl(0deg 0% 7%);
  --color-secondary-a: hsl(0deg 0% 17%);
  --color-accent: hsl(191deg 88% 41%);
  --color-accent-a: hsl(191deg 88% 51%);
  --color-success: hsl(122deg 38% 51%);
  --color-success-a: hsl(122deg 38% 61%);
  --color-danger: hsl(1deg 82% 60%);
  --color-danger-a: hsl(1deg 82% 70%);
  --color-gray20: #cccccc;
  --color-black60: rgba(0, 0, 0, 0.6);
  --color-black50: rgba(0, 0, 0, 0.5);
  --color-black38: rgba(0, 0, 0, 0.38);
  --color-black12: rgba(0, 0, 0, 0.12);
}

a {
  text-decoration: none;
  color: initial;
  transition: opacity 150ms ease, color 150ms ease;
}

a:hover {
  opacity: 0.75;
  color: var(--color-primary);
}

html {
  font-size: 62.5%; /* => 10px => 1rem */
}

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
 Antd
*/
[ant-click-animating-without-extra-node='true']::after {
  --antd-wave-shadow-color: currentColor;
}

.ant-btn-group > .ant-btn:first-child:not(:last-child) {
  display: none;
}

.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
  border-radius: 50%;
}

.ant-btn-group > .ant-btn:last-child:not(:first-child) .anticon {
  font-size: 2rem;
}

.ant-btn {
  transition-property: color, background-color, border-color, box-shadow, opacity;
}

.fullscreen-spin {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  color: var(--color-primary);
}

/*
 Typography
*/
.text-hero {
  font-size: 6.4rem;
  line-height: 1;
}

.text-title {
  font-size: 4.2rem;
  line-height: 1.25;
}

.text-heading {
  font-size: 3.2rem;
  line-height: 1.25;
}

.text-subtitle {
  font-size: 2.6rem;
  line-height: 1.28;
}

.text-subheading {
  font-size: 2.2rem;
  line-height: 1.5;
}

.text-article {
  font-size: 1.8rem;
  line-height: 1.32;
}

.text-paragraph,
:where(body) {
  font-size: 1.6rem;
  line-height: 1.5;
}

.text-body {
  font-size: 1.4rem;
  line-height: 1.42;
}

.text-label {
  font-size: 1.3rem;
  line-height: 1.52;
}

.text-input {
  font-size: 1.4rem;
  line-height: 1.42;
}

.text-description {
  font-size: 1.2rem;
  line-height: 1.33;
}

.text-footnote {
  font-size: 1.1rem;
  line-height: 1.45;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 700;
}

.text-semibold {
  font-weight: 600;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-hebrew {
  direction: rtl;
  text-align: left;
}

.display-ib {
  display: inline-block;
}
