/* type: default */
.btn:global(.ant-btn) {
  color: white;
  background: var(--color-secondary);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:global(.ant-btn:hover),
.btn:global(.ant-btn:active),
.btn:global(.ant-btn:focus) {
  background-color: var(--color-secondary-a);
  color: white;
}

/* type: primary */
.btn:global(.ant-btn-primary) {
  color: white;
  background: var(--color-primary);
}

.btn:global(.ant-btn-primary:hover),
.btn:global(.ant-btn-primary:active),
.btn:global(.ant-btn-primary:focus) {
  background-color: var(--color-primary-a);
  color: white;
}

/* type: text */
.btn:global(.ant-btn-text) {
  color: rgba(0, 0, 0, 0.87);
  background: none;
}

.btn:global(.ant-btn-text:hover),
.btn:global(.ant-btn-text:active),
.btn:global(.ant-btn-text:focus) {
  background: none;
  color: var(--color-black38);
}

/* dangerous text */
.btn:global(.ant-btn-text.ant-btn-dangerous) {
  color: var(--color-danger);
  background: none;
}

.btn:global(.ant-btn-text.ant-btn-dangerous:hover),
.btn:global(.ant-btn-text.ant-btn-dangerous:active),
.btn:global(.ant-btn-text.ant-btn-dangerous:focus) {
  background: none;
  color: var(--color-danger-a);
}

/* dangerous */
.btn:global(.ant-btn-dangerous) {
  color: white;
  background: var(--color-danger);
}

.btn:global(.ant-btn-dangerous:hover),
.btn:global(.ant-btn-dangerous:active),
.btn:global(.ant-btn-dangerous:focus) {
  background-color: var(--color-danger-a);
  color: white;
}

/* custom type: accent */
.btn.accent {
  color: white;
  background: var(--color-accent);
}

.btn.accent:hover,
.btn.accent:active,
.btn.accent:focus {
  background-color: var(--color-accent-a);
  color: white;
}

/* custom type: success */
.btn.success {
  color: white;
  background: var(--color-success);
}

.btn.success:hover,
.btn.success:active,
.btn.success:focus {
  background-color: var(--color-success-a);
  color: white;
}

/* custom type: dimmed */
.btn.dimmed {
  color: white;
  background: var(--color-black50);
}

.btn.dimmed:hover,
.btn.dimmed:active,
.btn.dimmed:focus {
  background-color: var(--color-black60);
  color: white;
}

/* custom type: white */
.btn.white {
  color: var(--color-black60);
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.btn.white:hover,
.btn.white:active,
.btn.white:focus {
  background-color: white;
  color: black;
}

/* shape: circle */
button:global(.ant-btn-circle) {
  width: 42px;
  height: 42px;
}

button:global(.ant-btn-circle > *) {
  vertical-align: middle;
}

/* size */
button.btn:global(.ant-btn-lg) {
  height: 52px;
  padding: 0 32px;
  font-size: 2.2rem;
}

button.btn:global(.ant-btn-lg.ant-btn-circle) {
  width: 52px;
  padding: 0;
}

button.btn:global(.ant-btn-sm) {
  height: 34px;
  font-size: 1.2rem;
  padding: 0 16px;
}

button.btn:global(.ant-btn-sm.ant-btn-circle) {
  width: 34px;
  padding: 0;
}

/* icon */
.btn:global(.ant-btn-round) :global(.anticon) {
  margin-left: -0.3em;
}

/* disabled */
.btn:global(.ant-btn[disabled]) {
  opacity: 0.7;
}
