.field {
  position: relative;

  --field-height: 48px;
}

.field :global(.ant-input-affix-wrapper),
.field :global(.ant-input-affix-wrapper:focus),
.field :global(.ant-input-affix-wrapper-focused) {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  box-shadow: none;
}

.field input,
.field > :global(.ant-input-affix-wrapper > input.ant-input) {
  font-size: initial;
  line-height: initial;
  border-radius: 6px;
  padding: 0 40px 0 12px;
  height: var(--field-height);
  border: none;
  outline: none;
  box-shadow: inset 0 0 0 1px var(--color-gray20);
  background-color: white !important; /* override system !important rule */
  transition: box-shadow 200ms ease;
}

.field input:focus,
.field :global(.ant-input-affix-wrapper input.ant-input:focus) {
  box-shadow: inset 0 0 0 2px var(--color-primary) !important; /* override antd !important rule */
}

.field :global(.ant-input-suffix) {
  position: absolute;
  margin: 0;
}

.field:not(.withCount) :global(.ant-input-suffix) {
  right: 14px;
  height: 100%;
}

.field.withCount :global(.ant-input-suffix) {
  bottom: calc(100% + 4px);
  right: 0;
  font-size: 1.1rem;
  color: var(--color-black60);
  word-spacing: -0.2rem;
  letter-spacing: -0.05rem;
}

.field :global(.ant-input-show-count-suffix) {
  color: inherit;
}

.field label {
  display: block;
  margin-bottom: 0.8rem;
}

.hint {
  color: var(--color-black60);
  margin-left: 4px;
}

/* preset: text-hero */
.textHero {
  height: auto;
  text-align: center;
}

.textHero label {
  font-size: 1.6rem;
}

.textHero :global(.ant-input-affix-wrapper) {
  display: block;
}

.textHero :global(.ant-input-affix-wrapper::before) {
  display: none;
}

.field.textHero input,
.field.textHero > :global(.ant-input-affix-wrapper) > :global(input.ant-input) {
  font-size: 3.8rem;
  box-shadow: none !important;
  text-align: center;
  padding: 0;
}

.textHero.withCount :global(.ant-input-suffix) {
  position: static;
  padding-top: 0.5em;
  display: block;
  text-align: center;
  font-size: 1.6rem;
}
