.contentHeader {
  box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
}

.contentHeader nav {
  display: flex;
  align-items: stretch;
}

.contentHeader nav a {
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  padding-right: 50px;
}

.contentHeader nav a:global(.active) {
  color: var(--color-primary);
  border-bottom-color: currentColor;
}

.contentHeader nav a:hover {
  color: var(--color-primary-a);
}
