/* Table */
.ant-table {
  font-size: 1.6rem;
}

.ant-table .ant-table-cell.ant-table-cell-fix-left {
  background-color: white;
}

.ant-table .ant-table-thead > tr > th,
.ant-table td.ant-table-column-sort {
  background: none;
}

.ant-table .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #fafafa;
}

.ant-table-thead th::before,
.ant-table .ant-table-column-sorter {
  display: none !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  color: var(--color-black60);
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding-left: 20px;
  line-height: 1;
  height: 70px;
  font-weight: 400;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}

.ant-table-tbody > tr:hover > td,
.ant-table-thead > tr:hover > th {
  background-color: #f7f7f7;
}

.ant-table tbody td:first-child {
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-table tbody td:first-child a {
  display: block;
  padding: 26px 0;
  color: inherit;
  line-height: 1.4;
}

.ant-table tbody td:first-child:hover {
  color: var(--color-primary);
}

.ant-table tbody td:last-child {
  padding-top: 0;
  padding-bottom: 0;
}

/* Table Pagination */
.ant-table-pagination .ant-pagination-item:hover {
  border-color: var(--color-primary);
}

.ant-table-pagination .ant-pagination-item:hover a {
  color: var(--color-primary);
  opacity: 1;
}

.ant-table-pagination .ant-pagination-item-active {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.ant-table-pagination .ant-pagination-item-active a,
.ant-table-pagination .ant-pagination-item-active:hover a {
  color: white;
}

.ant-table-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.ant-table-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.ant-table-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-table-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--color-primary);
}

/* Table custom filter icon */
.ant-custom-filter-icon {
  margin-left: 6px;
  opacity: 0;
  vertical-align: middle;
  transition: 100ms ease;
  color: rgba(0, 0, 0, 0.45);
}

.ant-table-column-sort .ant-custom-filter-icon {
  opacity: 1;
}

.ant-table-column-sort[aria-sort='descending'] .ant-custom-filter-icon {
  transform: scaleY(-1);
}
