.title {
  color: var(--color-black60);
  margin-bottom: 16px;
}

.links > a,
.nav :global(.ant-anchor-link) {
  display: block;
  padding: 4px 0;
}

.nav :global(.ant-anchor-wrapper),
.nav :global(.ant-anchor) {
  padding: 0;
  margin: 0;
  color: initial;
}

.nav :global(.ant-anchor-ink) {
  display: none;
}

.nav :global(.ant-anchor-link-active > .ant-anchor-link-title),
.links > a:global(.active) {
  color: var(--color-primary);
}
