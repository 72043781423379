.app {
  --aside-width: 240px;

  background-color: #fff;
  display: grid;
  padding-left: var(--aside-width);
}

.btn {
  position: absolute;
  top: 8px;
  left: 14px;
}

.app.asideCollapsed {
  --aside-width: 60px;
}

.asideCollapsed .aside > :not(.btn) {
  display: none;
}

.aside {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--aside-width);
  background-color: #fafafa;
  padding: 45px 24px;
  border-right: 1px solid #f1f1f1;
}

.aside a {
  text-decoration: none;
  color: black;
}

.content {
  overflow: hidden;
}

.logo {
  display: block;
  width: 140px;
  max-width: 100%;
  margin-left: -10px;
  margin-bottom: 52px;
}

.logo img {
  width: 100%;
}

.app.stats {
  --aside-width: 0px;
}

.app.stats .aside {
  display: none;
}
