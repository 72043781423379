.checkbox {
  cursor: pointer;
}

:global(.ant-checkbox-inner),
:global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: var(--color-black38);
}

:global(.ant-checkbox-wrapper:hover .ant-checkbox-inner),
:global(.ant-checkbox:hover .ant-checkbox-inner),
:global(.ant-checkbox-checked::after) {
  border-color: var(--color-primary);
}

:global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

/*
.label {
  margin-bottom: 0.8rem;
}

.inlineValue {
  color: black;
}

.checked .inlineValue {
  color: var(--color-primary);
}

:global(.ant-switch) {
  background: var(--color-secondary);
}

:global(.ant-switch-checked) {
  background: var(--color-primary);
}
*/
